import { ShopUp } from '@shopuptech/api-client'
import { generateId } from 'utils/helpers'

export const client = ShopUp({
  baseUrl: '/api/auth',
  request: {
    fetch: window.fetch,
  },
})

client.requestHook.before((options) => {
  options.headers['X-Request-Signature'] = generateId(
    process.env.REACT_APP_HASH_SECRET ?? '',
    2
  )
})

export const api = client.request
