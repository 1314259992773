import PrivateRoute from 'components/Route/PrivateRoute'
import Dashboard from 'pages/Dashboard'
import ForgotPassword from 'pages/forgot-password/ForgotPassword'
import LoginPage from 'pages/login/Main'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import { checkAuthStatus } from 'store/currentUser'

function App() {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(checkAuthStatus())
  }, [dispatch])
  return (
    <Switch>
      <Redirect exact from="/" to="/dash" />
      <Route path="/login" component={LoginPage} />
      <Route path="/forgot-password/:token?" component={ForgotPassword} />
      <PrivateRoute path="/dash" component={Dashboard} />
    </Switch>
  )
}

export default App
