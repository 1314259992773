import { sha256 } from 'js-sha256'

export function generateId(secret, duration) {
  const timeSlot = Math.floor(Date.now() / 1000 / duration)

  const message = JSON.stringify({ t: timeSlot })

  const signature = sha256.hmac.update(secret, message)

  return signature.hex()
}
